// core version + navigation, pagination modules:
import Swiper, { Navigation, Pagination } from 'swiper';

// configure Swiper to use modules
Swiper.use([Navigation, Pagination]);

export default class ProjectDetail {
    constructor() {
        $(document).ready(() => {
            this.initMap();

            var swiper = new Swiper('.project-detail-swiper', {
                loop: true,
                spaceBetween: 100,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
            });

            $(".images-trio-link").on('click', (e) => {
                e.preventDefault();

                $(".project-detail-swiper-wrap").css("visibility", "visible");
            });

            $(".project-detail-swiper-bg, .swiper-button-close").on('click', (e) => {
                $(".project-detail-swiper-wrap").css("visibility", "hidden");
            });

        });
    }

    initMap() {
        var map = L.map('map-detail', {minZoom: 7, maxZoom: 13, worldCopyJump: true});
        var markerLayer;
        var markers=[];

        L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions"  target="_blank">CARTO</a>',
            maxZoom: 14
        }).addTo(map);

        var defaultIcon = L.icon({
            iconUrl: '/dist/images/marker-icon.svg',
            shadowUrl: '',
            iconSize:     [25, 41], // size of the icon
            shadowSize:   [0, 0], // size of the shadow
            //iconAnchor:   [20, 20], // point of the icon which will correspond to marker's location
            shadowAnchor: [0, 0],  // the same for the shadow
            //popupAnchor:  [1, -27] // point from which the popup should open relative to the iconAnchor
        });

        var markers = L.markerClusterGroup({showCoverageOnHover: false, chunkedLoading: true, maxClusterRadius: 50});
        var markerList = [];

        map.on('load', () => {
            if (window.marker) {
                markerList.push( L.marker(window.marker, {icon: defaultIcon}).addTo(map));
            }
            if (window.polygon) {
                markerList.push(L.polygon(window.polygon, {color: '#6E8900', opacity: 0.6, weight: 2}).addTo(map));
            }
            if (window.markersList) {
                window.markersList.forEach(marker => {
                    markerList.push( L.marker(marker, {icon: defaultIcon}).addTo(map));
                });
            }
            if (window.polygonsList) {
                window.polygonsList.forEach(polygon => {
                    markerList.push(L.polygon(polygon, {color: '#6E8900', opacity: 0.6, weight: 2}).addTo(map));
                });
            }

            if (markerList.length > 0 ) {
                markers.addLayers(markerList);
                markerLayer = map.addLayer(markers);
                map.fitBounds(markers.getBounds(), {maxZoom: 10, padding:[15,15]});
            }

        });

        map.setView([52.21158, 5.600498], 8);

    }
}