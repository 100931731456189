export default class Utils {
    static getQueryStringParams(query) {
        return query
            ? (/^[?#]/.test(query) ? query.slice(1) : query)
                .split('&')
                .reduce((params, param) => {
                        let [key, value] = param.split('=');
                        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                        return params;
                    }, {}
                )
            : {}
    }

    static setCookie(key, value) {
        var expires = new Date();
        expires.setTime(expires.getTime() + (365 * 24 * 60 * 60 * 1000));
        document.cookie = key + '=' + value + ';path=/;expires=' + expires.toUTCString();
    }

    static getCookie(name) {
        var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return v ? v[2] : null;
    }

    static deleteCookie(name) {
        setCookie(name, '', -1);
    }

    static distributeInsects() {
        document.querySelectorAll('.aside-insect').forEach((el) => {
            let key = Array.from(el.parentNode.children).indexOf(el);
            let minPos = (el.parentElement.clientHeight / el.parentNode.children.length) * key;
            let maxPos = (el.parentElement.clientHeight / el.parentNode.children.length) * (key+1) - el.querySelector('img').clientHeight;
            //console.log(el.querySelector('img').clientHeight);
            // console.log(minPos);
            // console.log(maxPos);
            el.style.height = el.querySelector('img').clientHeight+"px";
            el.style.marginTop = 'calc('+ (Math.floor(Math.random() * (maxPos - minPos) + minPos)) + 'px + 8rem)';
        });
    }
}