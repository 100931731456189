import Utils from './Utils';

export default class CookieConsent {
	constructor(analytics, analyticsId) {

		// cookie bar consent
		$('[data-cookie-consent] .cookies-yes').on('click', () => {
			$('[data-cookie-consent]').css({ opacity: 0 });
			setTimeout(() => {
				$('[data-cookie-consent]').addClass('hide');
			}, 600);
			Utils.setCookie('cookie-consent', 1);
		});

		if (!Utils.getCookie('cookie-consent')) {
			$('[data-cookie-consent]').removeClass('hide');
		} else {
			switch (analytics) {
				case 'ga':
					this.initGA(analyticsId);
					break;
				default:
					break;
			}
		}
	}
	initGA(id) {
		window.dataLayer = window.dataLayer || [];

		function gtag() {
			dataLayer.push(arguments);
		}
		gtag('js', new Date());
		gtag('config', id);
	}
}