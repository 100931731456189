import '../scss/index.scss';

import jQuery from 'jquery';
import simpleshare from './lib/simpleshare.jquery';
import inView from 'in-view';
import socialshares from 'socialshares';

// useful polyfills
import smoothscroll from 'smoothscroll-polyfill';

// tooltips/popups
import tippy, { inlinePositioning } from 'tippy.js';

import './lib/ScreenDetector'; // initializes screensize etc.
import './lib/collapsible';
import CookieConsent from './lib/CookieConsent';
import FormValidator from './lib/FormValidator.js';
import Modals from './lib/Modals.js';
import QueryString from './lib/QueryString.js';
import InfiniteScroll from './lib/InfiniteScroll.js';
import Utils from "./lib/Utils";

import leaflet from 'leaflet';
import leafletMarkerCluster from 'leaflet.markercluster';
import leafletProvider from 'leaflet-providers';
import leafletDraw from 'leaflet-draw';

import TinyDatePicker from 'tiny-date-picker';

// import js for specific pages
import PageHome from './pages/Home.js';
import PageUpdates from './pages/Updates.js';
import PartnerIndex from "./pages/Partners";
import SupporterIndex from "./pages/Supporters";
import Search from "./pages/Search";
import ProjectIndex from "./pages/ProjectIndex";
import ProjectDetail from "./pages/ProjectDetail";
import ProjectForm from "./pages/ProjectForm";
import ThemesOverview from "./pages/Themes";
import InnovationFundForm from "./pages/InnovationFundForm";

const page = $('body').data('page-key');
switch (page) {
    case 'home':
        new PageHome();
        break;
    case 'partners':
        new PartnerIndex();
        break;
    case 'partner-form':
        $(document).ready(() => {
            const formCategoryChoicePublic = document.getElementById('partner_form_category_0');
            const formCategoryChoicePrivate = document.getElementById('partner_form_category_1');
            const formCategoryBlockPrivate = document.getElementById('partner_form_sub-category-private');
            const formCategoryBlockPublic = document.getElementById('partner_form_sub-category-public');

            // partners form
            function updateFields(except){
                formCategoryBlockPublic.classList.add('hide');
                formCategoryBlockPrivate.classList.add('hide');
                except.classList.remove('hide');

                // prevent multiple radios from being checked
                var privateRadios = document.querySelectorAll('#partner_form_sub-category-private input');
                var publicRadios = document.querySelectorAll('#partner_form_sub-category-public input');
                var allRadios = [...privateRadios, ...publicRadios];

                for (var i = 0; i < allRadios.length; i++) {
                    allRadios[i].checked = false;
                }
            }

            formCategoryChoicePrivate.addEventListener('click', event => {
                updateFields(formCategoryBlockPrivate);
            });

            formCategoryChoicePublic.addEventListener('click', event => {
                updateFields(formCategoryBlockPublic);
            });

            $('#partner_form input#partner_form_address').val('v' + (Math.random() * 42)); //Fill in honeypot trap field

            // checkboxes generated by symphony cannot be made required, so this is a workaround
            $('.wrapper-of-required-input input[type=checkbox]').prop('required',true);
        });
        break;
    case 'supporters':
        new SupporterIndex();
        break;
    case 'supporter-form':
        $('#supporter_form input#supporter_form_address').val('v' + (Math.random() * 42)); //Fill in honeypot trap field
        break;
    case 'updates':
        new PageUpdates();
        break;
    case 'search':
        new Search();
        break;
    case 'projects':
        new ProjectIndex();
        break;
    case 'project-detail':
        new ProjectDetail();
        break;
    case 'project-form':
        new ProjectForm();
        break;
    case 'themes-overview':
        new ThemesOverview();
        break;
    case 'innovatiefonds-form':
        new InnovationFundForm();
        break;
}

function setOnScroll(cb) {
	// Reference: http://www.html5rocks.com/en/tutorials/speed/animations/

	let last_known_scroll_position = 0;
	let ticking = false;

	window.addEventListener('scroll', e => {
		last_known_scroll_position = window.scrollY;

		if (!ticking) {
			window.requestAnimationFrame(() => {
				cb(last_known_scroll_position);
				ticking = false;
			});
			ticking = true;
		}
	});
}

$(document).ready(() => {
    const navMenuToggle = document.querySelector('.nav-menu-toggle');
    const topbar = document.querySelector('#topbar');

	window.scroll(0,-1); // scroll one pixel up on page load to trigger menu styling if user has scrolled before page refresh

	let scrollExceedsHeight = false;
	setOnScroll((pos) => {
		if (pos > topbar.getBoundingClientRect().height) {
			if (scrollExceedsHeight === false) {
				scrollExceedsHeight = true;
				topbar.classList.add('alternate');
			}
		} else {
			if (scrollExceedsHeight === true &&  navMenuToggle.checked == false ) {
				scrollExceedsHeight = false;
				topbar.classList.remove('alternate');
			}
		}
	})

	// simpleshare
	$('.simpleshare').simpleShare({ facebook: true, twitter: true, linkedin: true, whatsapp: false });

	smoothscroll.polyfill();

	//Modals.initModals(); initializes modals from existing HTML
	new FormValidator().processForms();

	new CookieConsent('ga', 'UA-xxxxxxxxx-x');

	// open external links in new window
	//$('.content-container').find('a[href ^= "http://"], a[href ^= "https://"]').attr('target', '_blank');

	// $('abbr[title], img[title], [data-definition]').each((i, el) => {
    //
	// 	const $definition = $(el);
	// 	let title = $definition.attr('title') ? $definition.attr('title') : $definition.find('.tooltip-content:first');
	// 	if (!title) return true;
	// 	//$definition.data('title', $definition.attr('title')); // get title attribute value
	// 	$definition.data('title', title); // add title attribute value to data-title attribute (when previous line had no success)
	// 	$definition.removeAttr('title'); // remove the title attribute, removing browser tooltip
    //
	// 	let tippyInst = tippy(el, {
	// 		content: title instanceof jQuery ? title.html() : title,
	// 		animation: 'scale-subtle',
	// 		theme: 'light',
	// 		interactive: 'true',
	// 		appendTo: document.body,
	// 		inlinePositioning: true,
	// 		allowHTML: true,
	// 		popperOptions: {
	// 			positionFixed: true
	// 		}
	// 		//showOnCreate: true
	// 	}, [inlinePositioning]);
	// 	$(el).data('tippy', tippyInst);
    // });
    
    // A class needs to be added to topbar in order to get a white background when mobile menu is click while scroll is at top of page

    navMenuToggle.addEventListener('click', event => {
        if (scrollExceedsHeight === false) {
            if ( navMenuToggle.checked == true ) {
                topbar.classList.add('alternate');                
                $('.hamburger').attr('aria-pressed', 'true');
                $('.hamburger svg title').text('Menu sluiten');
            } else {
                topbar.classList.remove('alternate');
                $('.hamburger').attr('aria-pressed', 'false');
                $('.hamburger svg title').text('Menu');
            }
        }
    });

    // when Enter is pressed while hamburger is focused
    $(document).on('keypress',function(e) {
        if(e.which == 13 && $(".hamburger").is(":focus") ) {
            navMenuToggle.click();
            toggleAria($(".hamburger"));
        }
    });

    $(".hamburger").click(function(e){
        toggleAria($(".hamburger"));
    });

    function toggleAria(item) {
        if ( item.attr('aria-pressed') == 'true' ) {
            item.attr('aria-pressed', 'false');
        } else {
            item.attr('aria-pressed', 'true');
        }
    }

    Utils.distributeInsects();

    // Search bar focus
    $('.search-bar-focus input').on('blur', function() {
        $(this).parents('.search-bar-focus').removeClass('focus');
    });

    $('.search-bar-focus input').on('focus', function(event) {
        $(this).parents('.search-bar-focus').addClass('focus');
    });

    // Skipnav link smooth scroll
    $(".skip-to-content-link").click(function(e){
        e.preventDefault();
        const y = $('main').offset().top;
        window.scrollTo({top: y-50, behavior: 'smooth'});
    });

    // Labels as placeholders
    $("form input.has-placeholder").on("blur input focus", function() {
        var $field = $(this).closest(".field");
        if (this.value) {
            $field.addClass("filled");
        } else {
            $field.removeClass("filled");
        }
    });

    $("form input.has-placeholder").on("focus", function() {
        var $field = $(this).closest(".field");
        if (this) {
            $field.addClass("filled");
        } else {
            $field.removeClass("filled");
        }
    });

    // check for prefilled inputs on pageload (chrome holds on to input text when going back to a previous page)
    $("form input.has-placeholder").each(function(e){
        if ( $(this).val() ) {
            var $field = $(this).closest(".field");
            
            $field.addClass("filled");
        }
    });

});
