import QueryString from '../lib/QueryString.js';
import InfiniteScroll from '../lib/InfiniteScroll.js';
import $ from "jquery";
import Utils from "../lib/Utils";

export default class UpdateHome {
    constructor() {

        // Initialize QueryString obj default behaviour
        const qs = new QueryString();

        // ---------------------[ Infinite Scroll Module ]--------------------- //
        /**
         * Initiate and set up the configuration of infinite scroll.
         */
        let segParam = qs.get('segment');
        let segment = segParam && !isNaN(segParam) ? parseInt(segParam) : 1;
        const config = {
            segment: segment,
            container: '#updates-content',
            autoFill: true,
            autoScroll: true,
            fetchOnInitiate: true,
            ajaxRoute: '/updates/data',
            getAjaxData: qs.getAllParams,
            onSuccess: this.appendUpdates,
            updateSegmentParam: segmentNum => qs.updateParam('segment', segmentNum)
        }
        const infiniteScroll = new InfiniteScroll(config);

        // Update the total count of items found based on the current filters
        this.updateItemCount(qs.get());

        // --------------------- [ Filter & Search ] --------------------- //
        const $filterForm = $('form#filter-bar');
        $filterForm.submit(e => e.preventDefault());
        $filterForm.change((e) => {
            let params = $(e.currentTarget).serialize();
            qs.set(decodeURI(params));

            $('#updates-content').empty();
            infiniteScroll.reset();
            infiniteScroll.fetchData();

            // Update the total count of items found based on the current filters
            this.updateItemCount(params);
        });

        // set up real-time search with 0.3s delay
        let typingTimeout;
        $('form#filter-bar input#search').on('input', (e) => {
            clearTimeout(typingTimeout);
            typingTimeout = setTimeout(() => $filterForm.trigger("change"), 300);
        });

        //restore filter state
        qs.getValues('kind[]').forEach(e => e && $(`form#filter-bar input[value=${e}]`).prop('checked', true));
        qs.getValues('theme[]').forEach(e => e && $(`form#filter-bar option[value=${e}]`).prop('selected', true));
        $(`form#filter-bar input#search`).val(qs.get('search'));

        $('#filters-toggle').on('click', () => {
            $('#filter-bar').toggleClass('open');
            toggleAria($('#filters-toggle'));
            return false;
        });

        function toggleAria(item) {
            if ( item.attr('aria-pressed') == 'true' ) {
                item.attr('aria-pressed', 'false');
            } else {
                item.attr('aria-pressed', 'true');
            }
        }
    }

    updateItemCount(params) {
        const $displayCount = $('#item-count');
        if ($displayCount.length > 0) {
            $.ajax({
                url: '/updates/count',
                method: 'GET',
                data: params,
                dataType: 'json',

                success: (res, status, XHR) => {
                    if (res && typeof res.itemCount !== undefined) {
                        $displayCount.text(res.itemCount + ' item' + (res.itemCount != 1 ? 's' : ''));
                    } else {
                        $displayCount.text('? items');
                    }
                },
                error: (error) => {
                    console.error(error);
                }
            });
        }
    }


    /**
     * Callback for Ajax infinity scroll request
     */
    appendUpdates(res) {
        res.forEach((updateItem) => {
            // Yes, you read this correct: class="col-"
            // Basically this is needed to undo some grid styling regarding letter and word spacing
            // Also the defined stylesheet updates.scss adds col-md-3 or col-md-6 to the card!!
            $('#updates-content').append(`
                <a href="/updates/${updateItem.key}/${updateItem.id}" class="col- card-update">
                    <div class="image-container">
                        ${updateItem.imageHtml}
                    </div>
                    <div class="text-overlay">
                        <h3>${updateItem.title}</h3>
                        <p class="summary">${updateItem.summary}</p>
                    </div>
                </a>
            `);
        });

        Utils.distributeInsects();
    }
}