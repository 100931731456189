import QueryString from '../lib/QueryString.js';
import InfiniteScroll from '../lib/InfiniteScroll.js';
import $ from "jquery";

export default class Search {
    constructor() {

        // Initialize QueryString obj default behaviour
        const qs = new QueryString();
        let updatesSegment = 1;
        let documentsSegment = 1;

        $("#load-updates").on('click', (e) => {
            e.preventDefault();
            return new Promise((resolve, reject) => {

                let dataParams = qs.getAllParams();
                dataParams.segment = updatesSegment;

                $.ajax({
                    url: '/search/updates',
                    method: 'GET',
                    data: dataParams,
                    dataType: 'json',
                    success: (res, status, XHR) => {
                        if ($(res).length) {
                            const noMoreContent = XHR.getResponseHeader('NoMoreContent');
                            updatesSegment++ ;
                            if (noMoreContent) {
                                $("#load-updates").hide();
                            }

                            // in case the field 'noMoreContent' doesn't exist, the fallback is falsy value
                            this.appendUpdates(res);
                        } else {
                            resolve(false);
                        }
                    },
                    error: (res, status, XHR ) => {console.log(res); }
                });
            });
        });

        $("#load-documents").on('click', (e) => {
            e.preventDefault();
            return new Promise((resolve, reject) => {

                let dataParams = qs.getAllParams();
                dataParams.segment = documentsSegment;

                $.ajax({
                    url: '/search/documents',
                    method: 'GET',
                    data: dataParams,
                    dataType: 'json',
                    success: (res, status, XHR) => {
                        if ($(res).length) {
                            const noMoreContent = XHR.getResponseHeader('NoMoreContent');
                            documentsSegment++ ;
                            if (noMoreContent) {
                                $("#load-documents").hide();
                            }

                            // in case the field 'noMoreContent' doesn't exist, the fallback is falsy value
                            this.appendDocuments(res);
                        } else {
                            resolve(false);
                        }
                    },
                    error: (res, status, XHR ) => {console.log(res); }
                });
            });
        });
    }


    /**
     * Callback for Ajax  request
     */
    appendUpdates(res) {
        res.forEach((updateItem) => {
            $('#updates-content').append(`
                <div class="col-sm-6 col-md-3">
                    <a href="/updates/${updateItem.key}/${updateItem.id}" class="card-update card-search">
                        <div class="image-container">
                            ${updateItem.imageHtml}
                        </div>
                        <div class="text-overlay">
                            <h3>${updateItem.title}</h3>
                            <p class="summary">${updateItem.summary}</p>
                        </div>
                    </a>
                </div>
            `);
        });
    }

    /**
     * Callback for Ajax  request
     */
    appendDocuments(res) {
        res.forEach((documentItem) => {
            $('#documents-content').append(`
                <a class="misc-search-result" href="${documentItem.fullpath}">
                    <h3>${documentItem.title}</h3>
                    <p>${documentItem.description}</p>
                </a>
            `);
        });
    }
}