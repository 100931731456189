$(document).ready(() => {
	$('[data-toggle="collapse"]').each(function(){
		$(this).click(function(){
            let icon = $(this).find('img'); /* for Laudes, the html is '.collapsible-toggle img' */

            // toggle aria
            if ( $(this).find('button').attr('aria-expanded') == 'true' ) {
                $(this).find('button').attr('aria-expanded', 'false');
                icon.attr('src', icon.data('srcInactive'));
            } else {
                $(this).find('button').attr('aria-expanded', 'true');
                icon.attr('src', icon.data('srcActive'));
            }

            // toggle collapsible-body
            $($(this).data('target')).hide('slow').stop(true, false, true).toggle('fast');
		});

        // toggle collapsible on enter press
        $(this).on('keypress',function(e) {
            if(e.which == 13 && $(this).is(":focus") ) {
                $(this).click();
                toggleAria($(this));
            }
        });
	});
});